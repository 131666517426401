// src/components/About.js
import React from "react";

function MaintenancePage(debugInfo) {
  return (
    <div className="bg-background main-container py-8">
      <div className="container mx-auto px-4 sm:px-8 lg:px-16 xl:px-64">
        <div className="bg-card p-6 shadow-big">
          <h2 className="text-4xl text-navbar font-bold mb-12 mt-12 flex justify-center">
            Maintenance ahead!
          </h2>
          <div className="text-description mb-6 text-center mr-10 ml-10 lg:mr-12 lg:ml-12 xl:mr-24 xl:ml-24">
            <p className="mb-12">
              Welcome,
              <br />
              <br />
              We're undergoing maintenance, wait for the updates!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MaintenancePage;
