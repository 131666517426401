// src/App.js
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import TopicPage from './components/TopicPage';
import About from './components/About';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Shop from './components/Shop';
import Categories from './components/Categories';
import ProductPage from './components/ProductPage';
import CartPage from './components/CartPage';
import SigilPage from './components/SigilPage';
import Analytics from './components/Analytics';
import TrackPageView from './components/TrackPageView';
import AstrologicalForm from './components/AstrologicalForm';
import MaintenancePage from './components/MaintenancePage';
import { Helmet } from 'react-helmet';

function App() {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
  const [isAllowed, setIsAllowed] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [debugInfo, setDebugInfo] = useState(null);

  useEffect(() => {
    fetch('/api/maintenance-status/')
      .then(response => response.json())
      .then(data => {
        setIsMaintenanceMode(data.maintenance_mode);
        setIsAllowed(data.allowed);
        setDebugInfo(data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching maintenance status:', error);
        setIsMaintenanceMode(true);
        setIsAllowed(false);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Analytics /> {/* Initialize Google Analytics */}
      <TrackPageView /> {/* Track page views on route change */}
      <Helmet>
        <title>Hederum</title>
        <meta name="description" content="An all-in-one website with informations, sigil creation and more to come ..." />
      </Helmet>
      <div className="flex flex-col min-h-screen">
        <header className="header">
          <Navbar maintenance={!isAllowed}/>
        </header>
        <main className="flex-grow">
        {isMaintenanceMode && !isAllowed ? (
          <MaintenancePage debugInfo={debugInfo} />
        ) : (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/*" element={<TopicPage />} />
            <Route path="/about" element={<About />} />
            {/*<Route path="/shop" element={<Categories />} />
            <Route path="/shop/:slug" element={<Shop />} />*/}
            <Route path="/products/:slug" element={<ProductPage />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/create-sigil" element={<SigilPage />} />
            {/*<Route path="/astrological-form" element={<AstrologicalForm />} />*/}
          </Routes>)}
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;