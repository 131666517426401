import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useLocation, Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import Breadcrumbs from './Breadcrumbs';

// Custom SVG icons
const RobotIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <rect x="3" y="11" width="18" height="10" rx="2" />
    <circle cx="12" cy="5" r="2" />
    <path d="M12 7v4" />
    <line x1="8" y1="16" x2="8" y2="16" />
    <line x1="16" y1="16" x2="16" y2="16" />
  </svg>
);

const CheckCircleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
    <polyline points="22 4 12 14.01 9 11.01" />
  </svg>
);

function TopicPage() {
  const location = useLocation();
  const path = location.pathname;

  const slugs = useMemo(() => path.split('/').filter((slug) => slug), [path]);

  const [topic, setTopic] = useState(null);
  const [loading, setLoading] = useState(true);

  const scrollToSubtopics = () => {
    document
      .getElementById('subtopics-section')
      .scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const fetchTopic = async () => {
      setLoading(true);
      if (slugs.length > 0) {
        const lastSlug = slugs[slugs.length - 1];
        try {
          const response = await axios.get(`/api/topics/${lastSlug}/`);
          setTopic(response.data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching topic:', error);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchTopic();
  }, [slugs]); // Using slugs in the dependency array

  const generatePath = (subtopic) => {
    return `${path}/${subtopic.slug}`;
  };

  return (
    <div className="bg-background main-container py-10">
      <div className="container mx-auto px-4 sm:px-8 lg:px-16 xl:px-32">
        <Breadcrumbs />
        <div
          className={`bg-card p-6 shadow-big ${loading ? 'loading' : 'loaded'}`}
        >
          {loading ? (
            <div>Loading...</div>
          ) : (
            <>
              <h2 className="text-3xl text-navbar font-bold mb-6 flex flex-col md:flex-row justify-between items-start">
                {/* Left Section: Topic name, AI Generated, and Reviewed */}
                <div>
                  {/* Topic Name */}
                  <div>{topic.name}</div>

                  {/* AI Generated Badge */}
                  {topic.ai_generated && (
                    <div className="mt-1 text-sm font-normal text-gray-500 flex items-center">
                      <RobotIcon />
                      <span className="ml-1">AI Generated</span>
                    </div>
                  )}

                  {/* Reviewed Badge */}
                  {topic.reviewed && (
                    <div className="mt-1 text-sm font-normal text-gray-500 flex items-center">
                      <CheckCircleIcon />
                      <span className="ml-1">Reviewed</span>
                    </div>
                  )}
                </div>

                {/* Right Section: Button (Responsive) */}
                <button
                  className="bg-navbar text-white text-sm md:text-base py-1.5 px-3 rounded hover:bg-accent mt-4 md:mt-0"
                  onClick={scrollToSubtopics}
                >
                  Go to Subtopics
                </button>
              </h2>
              <div
                className="text-description mb-6 sm:text-base md:text-sm"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(topic.html_content),
                }}
              />
              {topic.subtopics.length > 0 && (
                <div>
                  <h3
                    id="subtopics-section"
                    className="text-2xl text-navbar font-semibold mb-4 flex justify-center"
                  >
                    Subtopics
                  </h3>
                  <ul className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-6 mt-6">
                    {topic.subtopics.map((subtopic) => (
                      <Link
                        key={subtopic.id}
                        to={generatePath(subtopic)}
                        className="text-topic text-lg font-medium"
                      >
                        <li className="topic-card border border-border/90 transition-colors duration-100 hover:shadow-mid p-2 rounded flex flex-col items-center justify-center h-48 relative overflow-hidden">
                          <h3 className="mb-4 relative z-10">
                            {subtopic.name}
                          </h3>
                          {subtopic.image && (
                            <img
                              src={subtopic.image}
                              alt={subtopic.name}
                              className="absolute inset-0 w-full h-full object-cover object-top opacity-0 transition-opacity duration-100 topic-image brightness-100 contrast-50 grayscale-50 shadow-lg"
                            />
                          )}
                        </li>
                      </Link>
                    ))}
                  </ul>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default TopicPage;
