import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import BackButton from "./BackButton";
import { useCart } from "./CartContext";

function ProductPage() {
  const { slug } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const { addToCart } = useCart();

  useEffect(() => {
    axios
      .get(`/api/products/${slug}/`)
      .then((response) => {
        setProduct(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching product:", error);
        setLoading(false);
      });
  }, [slug]);

  const handleQuantityChange = (increment) => {
    setQuantity((prevQuantity) => {
      const newQuantity = prevQuantity + increment;
      return newQuantity < 1 ? 1 : newQuantity;
    });
  };

  return (
    <div className="bg-background main-container min-h-screen py-10">
      <div className="container mx-auto px-4 sm:px-8 lg:px-16 xl:px-64">
        <BackButton />
        <div
          className={`bg-card p-6 shadow-big ${loading ? "loading" : "loaded"}`}
        >
          {loading ? (
            <div>Loading...</div>
          ) : !product ? (
            <div>Product not found</div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="flex justify-center items-center">
                {product.image && (
                  <img
                    src={product.image}
                    alt={product.name}
                    className="max-w-full h-auto"
                  />
                )}
              </div>
              <div className="flex flex-col md:pt-24">
                <div className="grid grid-cols-1 divide-y">
                  <h2 className="text-3xl text-navbar font-bold mb-1 text-left">
                    {product.name}
                  </h2>
                  <p className="text-xl mb-6 pt-3">${product.price}</p>
                </div>
                <div className="text-description mb-6 flex-grow">
                  <h2 className="text-navbar mb-2 font-bold text-left">
                    Product description
                  </h2>
                  <div
                    dangerouslySetInnerHTML={{ __html: product.description }}
                    className="text-sm"
                  />
                </div>
                <h2 className="text-navbar mb-2 font-bold text-left">
                  Quantity
                </h2>
                <div className="flex justify-center space-x-4 md:pb-8 sm:pb-4 sm:mt-4 w-full">
                  <div className="flex items-center border border-gray-300 rounded w-full max-w-xs flex-1">
                    <button
                      onClick={() => handleQuantityChange(-1)}
                      className="w-1/3 h-full flex items-center justify-center border-r border-gray-300 bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600"
                    >
                      -
                    </button>
                    <input
                      type="number"
                      min="1"
                      value={quantity}
                      onChange={(e) =>
                        setQuantity(Math.max(1, parseInt(e.target.value)))
                      }
                      className="w-1/3 h-full text-center bg-white dark:bg-gray-700 border-none focus:outline-none focus:ring-accent [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                    />
                    <button
                      onClick={() => handleQuantityChange(1)}
                      className="w-1/3 h-full flex items-center justify-center border-l border-gray-300 bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600"
                    >
                      +
                    </button>
                  </div>

                  <button
                    onClick={() => addToCart(product, quantity)}
                    className="bg-navbar text-white py-2 rounded hover:bg-accent transition-colors duration-100 flex-1"
                  >
                    Add to Cart
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductPage;
