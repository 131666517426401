// src/components/About.js
import React from "react";

function About() {
  return (
    <div className="bg-background main-container py-8">
      <div className="container mx-auto px-4 sm:px-8 lg:px-16 xl:px-64">
        <div className="bg-card p-6 shadow-big">
          <h2 className="text-4xl text-navbar font-bold mb-12 mt-12 flex justify-center">
            About Us
          </h2>
          <div className="text-description mb-6 text-center mr-10 ml-10 lg:mr-12 lg:ml-12 xl:mr-24 xl:ml-24">
            <p className="mb-12">
              Welcome,
              <br />
              <br />
              your trusted source for in-depth knowledge about various occult
              practices and esoteric traditions. We aim to enlighten the world
              with the hidden mysteries, bringing insight and understanding on a
              wide variety of topics, including astrology, tarot, high magick,
              low magick, herbalism, LBRP, vision magick, chiromancy, chakras,
              and much more.
            </p>
            <h3 className="text-2xl text-navbar font-semibold mt-4 mb-2">
              Our Commitment
            </h3>
            <p className="mb-12">
              At <span className="font-semibold">Hederum</span>, we strongly
              believe in sound research and the ongoing quest for knowledge. We
              go through ancient texts, contemporary research, and hands-on
              practice to bring you the most accurate, meaningful information.
              Our team of writers includes scholars and avid practitioners who
              have devoted their lives to learning and teaching the multifaceted
              tapestry of occult wisdom.
            </p>
            <h3 className="text-2xl text-navbar font-semibold mt-4 mb-2">
              Our Approach
            </h3>
            <p className="mb-12">
              We not only realize the amplitude and complexity of the esoteric
              realms but also understand that this discovery should be given to
              you in the most elaborative, smooth, and accessible way possible.
              Be you an advanced practitioner or a curious seeker, our goal is
              to furnish you with content that resonates with your quest for
              understanding and enlightenment.
            </p>
            <h3 className="text-2xl text-navbar font-semibold mt-4 mb-2">
              Honesty and Integrity
            </h3>
            <p className="mb-12">
              Of course, we realize that this—in no small degree—will be a path
              that is ridden with challenges and uncertainties. Most
              importantly, interpretations and understandings on the subject of
              occult studies may differ, with continually new insights.
            </p>
            <h3 className="text-2xl text-navbar font-semibold mt-4 mb-2">
              Join Us on the Journey
            </h3>
            <p className="mb-12">
              We welcome you aboard this exciting journey deep into the bowels
              of occult knowledge. Check out our articles, guides, and
              resources, and feel free to drop a line for any questions,
              insights, or feedback. Together, let's further our knowledge and
              appreciation of the mystical and magical.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
