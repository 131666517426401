import React from 'react';
import ReactGA from 'react-ga4';


const Analytics = () => {
  React.useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
      gaOptions: {
        siteSpeedSampleRate: 100,
        cookieFlags: 'SameSite=None;Secure'
      }
    });
  }, []);

  return null;
};

export default Analytics;