import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import SearchBar from "./SearchBar";
import "./Home.css";

function Home() {
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(true);

  const [query, setQuery] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [results, setResults] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get("/api/topics/")
      .then((response) => {
        const topLevelTopics = response.data.filter(
          (topic) => topic.parent === null
        );
        setTopics(topLevelTopics);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching topics:", error);
        setLoading(false);
      });
  }, []);

  const handleSearch = async (e) => {
    setQuery(e.target.value);
    if (e.target.value.length > 0) {
      try {
        setIsSearching(true);
        const response = await axios.get(`api/search/?q=${e.target.value}`);
        setResults(response.data);
      } catch (error) {
        console.error("Error fetching search results:", error);
      } finally {
        setIsSearching(false);
      }
    } else {
      setResults([]);
    }
  };

  return (
    <div className="bg-background main-container py-10">
      <div className="container mx-auto px-4 sm:px-8 lg:px-16 xl:px-32">
        <div
          className={`bg-card p-6 shadow-big ${loading ? "loading" : "loaded"}`}
        >
          <h2 className="text-3xl text-navbar font-bold mb-6 flex justify-center">
            Topics
          </h2>
          <div>
            <SearchBar query={query} handleSearch={handleSearch} />
          </div>
          {results.length > 0 && (
            <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-6 mt-6">
              {results.map((result) => (
                <Link
                  key={result.id}
                  to={`/${result.slug}`}
                  className="text-topic text-xl font-medium"
                >
                  <li className="topic-card border border-border/90 transition-colors duration-100 hover:shadow-mid p-2 rounded flex flex-col items-center justify-center h-48 relative overflow-hidden">
                    <h3 className="mb-4 relative z-10">{result.name}</h3>
                    {result.image && (
                      <img
                        src={result.image}
                        alt={result.name}
                        className="absolute inset-0 w-full h-full object-cover object-top opacity-0 hover:opacity-100 transition-opacity duration-100 topic-image brightness-100 contrast-50 grayscale-50 shadow-lg"
                      />
                    )}
                  </li>
                </Link>
              ))}
            </ul>
          )}
          {results.length === 0 && query === "" && (
            <ul className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-6 mt-6">
              {topics.map((topic) => (
                <Link
                  key={topic.id}
                  to={`/${topic.slug}`}
                  className="text-topic text-lg font-medium"
                >
                  <li className="topic-card border border-border/90 transition-colors duration-100 hover:shadow-mid p-2 rounded flex flex-col items-center justify-center h-48 relative overflow-hidden">
                    <h3 className="mb-4 relative z-10">{topic.name}</h3>
                    {topic.image && (
                      <img
                        src={topic.image}
                        alt={topic.name}
                        loading="lazy"
                        className="absolute inset-0 w-full h-full object-cover object-top opacity-0 hover:opacity-100 transition-opacity duration-100 topic-image brightness-100 contrast-50 grayscale-50 shadow-lg"
                      />
                    )}
                  </li>
                </Link>
              ))}
            </ul>
          )}
          {results.length === 0 && query !== "" && !isSearching && (
            <div className="flex justify-center mt-6">
              <div className="bg-white text-navbar-600 border border-navbar rounded p-4 max-w-md w-full text-center mt-24 mb-24">
                <p>No topics found for your search query.</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;
