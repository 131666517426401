import React from 'react';
import ReactGA from "react-ga";

const ExportButton = ({ renderer, camera, scene, resetCamera }) => {
  const handleExport = () => {
    if (!renderer || !camera || !scene) return;

    // track export GA analytics
    ReactGA.event({
      category: "Sigil",
      action: "exported",
      label: "success",
      value: 123
    })

    // Save current camera state
    const restoreCamera = {
      position: camera.position.clone(),
      rotation: camera.rotation.clone(),
      zoom: camera.zoom,
    };

    // Save current renderer size
    const originalCanvasSize = {
      width: renderer.domElement.clientWidth, 
      height: renderer.domElement.clientHeight,
    };

    const originalAspect = camera.aspect;

    // Set the renderer to the desired export size
    const exportWidth = 512; // Desired export width 
    const exportHeight = 512; // Desired export height
    renderer.setSize(exportWidth, exportHeight);
    camera.aspect = exportWidth / exportHeight;
    camera.updateProjectionMatrix();

    // Reset camera to its original position
    resetCamera();

    // Trigger a render to ensure the latest scene state is captured
    renderer.render(scene, camera);

    // Convert canvas to image URL
    const imgData = renderer.domElement.toDataURL('image/png', 1.0); // PNG format

    // Create a link and set the download attribute to trigger download
    const link = document.createElement('a');
    link.download = 'sigil-image.gif';
    link.href = imgData;
    link.click();

    // Restore the camera and renderer to their previous states
    camera.position.copy(restoreCamera.position);
    camera.rotation.copy(restoreCamera.rotation);
    camera.zoom = restoreCamera.zoom;
    camera.aspect = originalAspect;
    camera.updateProjectionMatrix();

    renderer.setSize(originalCanvasSize.width, originalCanvasSize.height);
    camera.updateProjectionMatrix();
    renderer.render(scene, camera);
  };

  return (
    <button
      onClick={handleExport}
      className="sm:mt-0 mt-4 bg-navbar text-white py-2 px-4 rounded hover:bg-accent transition-colors duration-100"
    >
      Export
    </button>
  );
};

export default ExportButton;